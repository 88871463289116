<template>
    <div class="download-wrapper">
        <div class="header">
            <section v-if="hasDownloadAllPermission" class="header-filter">
                <div class="header-filter_options">
                    <Dropdown
                        v-if="hasDownloadAllPermission"
                        :disabled="isGenerate"
                        name="contestId"
                        title="競賽名稱"
                        :required="true"
                        placeholder="請選擇競賽..."
                        :options="contestIdDropdownOptions"
                        v-model="contestIdValue"
                    />
                </div>
                <div class="header-filter_button">
                    <p
                        v-if="isGenerate && hasDownloadAllPermission"
                        class="warning"
                    >
                        此競賽報表生產中，請稍候更新報表狀態
                    </p>
                    <Button
                        :disabled="isGenerate"
                        @click="downloadAllCompanyInfo"
                        :buttonWord="reportButtonText"
                        :iconClass="reportButtonStyle"
                        buttonStyle="blue-hollow"
                    />
                </div>
            </section>
            <div v-if="hasDownloadAllPermission" class="header-updated_create">
                <p class="updated">最後更新時間：{{ currentTime }}</p>
                <Button
                    @click="getReport"
                    buttonWord="更新報表狀態"
                    buttonStyle="blue"
                />
            </div>
            <div v-else class="header-updated">
                <p class="header-updated_text">
                    資料每4小時更新一次，建議下載前先更新檔案。
                    若檔案仍未更新至最新時間，請通知相關同仁協助產檔。
                </p>
                <Button
                    @click="getReport"
                    buttonWord="更新報表"
                    buttonStyle="blue"
                />
            </div>
            <DownloadModal
                title="競賽報表下載"
                modalSize="615px"
                :action="generateState"
            />
        </div>
    </div>
    <Table
        :isLoading="isTableLoading"
        :column-config="contestColumnConfig"
        :rows="sortedReport"
    >
        <template v-slot:reportLink="{ isHeader, row }">
            <div
                v-if="!isHeader"
                :class="['download-content-icon', { disabled: row.status }]"
                @click="downloadFile(row)"
            ></div>
        </template>
        <template v-slot:status="{ isHeader, row }">
            <div v-if="!isHeader" :class="{ generating: row.status }">
                {{ row.status ? row.status : '-' }}
            </div>
        </template>
    </Table>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'
import Button from '@/components/Button.vue'
import DownloadModal from '@/containers/backstage/DownloadModal.vue'
import Table from '../../components/Table.vue'
import {
    postBackstageGenerateReportAPI,
    getBackstageReportInfosAPI,
    getBackstageDownloadReportAPI
} from '@/assets/javascripts/api'
import { contestDownloadColumn } from '../../assets/javascripts/backstage/setting'

export default {
    name: 'ContestDownload',
    components: {
        Button,
        Dropdown,
        DownloadModal,
        Table
    },
    props: {
        contestIdDropdownOptions: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    emits: ['watch-download'],
    methods: {
        downloadAllCompanyInfo: async function () {
            if (!this.hasDownloadAllPermission) return
            let currentContestID = { contestId: +this.contestIdValue }

            try {
                let reportStateResponse = await postBackstageGenerateReportAPI(
                    currentContestID
                )
                this.isShowGenerateModal = true
                this.generateState = reportStateResponse.data.msg
                if (this.generateState === 'success') {
                    //因為後端資料 status 更新機制有延遲，需多幾秒才能保證取得更新狀態
                    setTimeout(async () => {
                        await this.getReport()
                        this.detectState()
                    }, 3000)
                }
            } catch (error) {
                console.log(error)
                this.generateState = 'generate-failure'
            }
        },
        downloadFile: async function (row) {
            if (row.status) return
            try {
                let response = await getBackstageDownloadReportAPI(row.id)
                let aLink = document.createElement('a')
                aLink.href = response.data.urls[0]
                //為了解決同頁面跳轉行為被監聽問題 *//
                aLink.setAttribute('target', '_blank')
                aLink.click()
            } catch (error) {
                console.log(error)
                this.generateState = 'download-failure'
            }
        },
        getReport: async function () {
            if (this.isTableLoading) return
            try {
                this.isTableLoading = true
                let response = await getBackstageReportInfosAPI()
                this.reportList = response.data.excelInfos
            } catch (err) {
                console.log(err)
                this.isShowGenerateModal = true
                this.generateState = 'updated-failure'
            } finally {
                this.isTableLoading = false
            }
        },
        getSortedReport: function (data) {
            return data.sort((a, b) => {
                return b.id - a.id
            })
        },
        detectState: function () {
            //status 代表報表的生產狀態: '生產中' / null
            this.isGenerate = !this.reportList.every(
                (report) => report.status === null
            )
        }
    },
    computed: {
        hasDownloadAllPermission: function () {
            return this.$hasPermission('generate')
        },
        sortedReport: function () {
            return this.getSortedReport(this.reportList)
        }
    },
    watch: {
        contestIdDropdownOptions: {
            handler(value) {
                if (value.length > 0) {
                    this.contestIdValue = this.contestIdDropdownOptions[0].value
                }
            },
            deep: true,
            immediate: true
        },
        sortedReport: {
            handler() {
                let date = this.$getDate()
                let currentDate = this.$formatDate(date, '.')
                let currentHour = new Date().getHours()
                let currentMin = new Date().getMinutes()
                let currentSeconds = new Date().getSeconds()
                //個位數分鐘加上0
                if (currentMin.toString().length < 2)
                    currentMin = '0' + currentMin
                if (currentSeconds.toString().length < 2)
                    currentSeconds = '0' + currentSeconds
                this.currentTime = `${currentDate} ${currentHour}:${currentMin}:${currentSeconds}`
                //每次報表更新都會檢查一次
                this.detectState()
            }
        }
    },
    data() {
        return {
            isTableLoading: false,
            isShowGenerateModal: false,
            isGenerate: false,
            currentTime: null,
            generateState: null,
            reportButtonText: '生產報表',
            reportButtonStyle: 'generate-excel-icon',
            reportList: [],
            contestIdValue: '',
            contestColumnConfig: contestDownloadColumn
        }
    },
    async mounted() {
        this.$showLoading()
        await this.getReport()
        this.detectState()
        this.$hideLoading()
    }
}
</script>

<style lang="scss" scoped>
:deep(.input-container) {
    margin-bottom: 0px;
}
:deep(.report-link) {
    .download-content-icon {
        cursor: pointer;
        width: 18px;
        height: 18px;
    }
    .generating {
        opacity: 0.4;
    }
}
:deep(.status) {
    .generating {
        color: $fourth-blue;
    }
}

:deep(.default-button) {
    width: 124px;
    @media screen and(max-width:576px) {
        margin-top: 0;
        width: 100%;
    }
}

.download-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    .header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        flex-basis: 100%;
        margin-bottom: 10px; //與下方 table 的距離設定
        @media screen and(max-width:576px) {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0;
            gap: 10px;
        }
        &-filter {
            display: flex;
            flex-direction: column;
            flex-basis: 100%;
            gap: 15px;
            border-bottom: 1px solid #d9d9d9d9;
            @media screen and(max-width:576px) {
                width: 100%;
            }
            &_options {
                display: grid;
                display: -ms-grid;
                grid-template-columns: repeat(auto-fill, 210px);
                -ms-grid-columns: repeat(auto-fill, 210px);
                justify-content: space-between;
                gap: 20px 130px;
                width: 100%;

                @media screen and (max-width: 576px) {
                    grid-template-columns: 100%;
                    :deep(.input-container) {
                        width: 100%;
                    }
                }
            }
            &_button {
                display: flex;
                gap: 10px;
                align-items: flex-end;
                justify-content: flex-end;
                margin-bottom: 15px;
                @media screen and (max-width: 576px) {
                    flex-direction: column;
                    align-items: start;
                }
            }
            .warning {
                font-size: 12px;
                color: $fourth-red;
                margin-top: 15px;
                @media screen and(max-width:576px) {
                    margin-top: 0px;
                    order: 1;
                }
            }
        }
        p {
            margin: 0;
        }
        &-updated_create {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            gap: 10px;
            button {
                @media screen and(max-width:576px) {
                    width: 100px;
                }
            }
        }
        &-updated {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            margin-left: auto;
            gap: 10px;

            &_text {
                color: #333333;
                margin-left: auto;
                max-width: 300px;
                text-indent: 30px;
                text-align: end;
                font-size: 12px;
                font-weight: 500;
                @media screen and(max-width:576px) {
                    text-align: start;
                    text-indent: 0;
                }
            }
            :deep(.default-button) {
                margin-top: 0;
            }
        }
    }
    .updated {
        margin: 0;
        margin-left: auto;
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 12px;
        color: $sixth-black;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .icon-size {
            width: 50px;
            height: 50px;
        }
    }
    .state {
        &-success {
            .title {
                color: $primary-green;
                font-size: 32px;
                font-weight: 700;
            }
            .distance {
                margin-bottom: 10px;
            }
        }
        &-failure {
            .title {
                color: $fourth-red;
                font-size: 32px;
                font-weight: 700;
            }
            .distance {
                margin-bottom: 10px;
            }
        }
    }

    &-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        .normal-text {
            color: $secondary-grey;
        }
        .loading-text {
            color: $fourth-red;
            font-weight: 500;
        }
    }
}
</style>
